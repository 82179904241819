import { initGsap } from "./gsapControls";
import { initFaq } from "./faq";
import { initCookiePolicy } from "./cookies";
// import { initTypewriter } from "./typewriter";
import { initMobileNav } from "./mobileNav";
import { initVideo } from "./video";
import { initFormControls } from "./formControls";
import { initMarquee } from "./marqueeControls";
// import { initMarqueeSlider } from "./keenSliderControls";
// import { initSmoothScroll } from "./smoothScroll";
// import { initJobListingsFetch } from "./job-listings";
// import { initContactForm } from "./contactForm";
import { initScrollControls } from "./scrollControls";
import { delay, windowReady } from "./utils";

export default init = () => {
  windowReady(() => {
    delay(initGsap('Home'));
    initFaq();
    initMobileNav();
    // initVideo();
    initFormControls();
    initMarquee();
    initScrollControls();
    // initMarqueeSlider();
    // initJobListingsFetch(false, 4);
    // initSmoothScroll();
    // initMobileNav();
    initCookiePolicy();
    // initContactForm();
    // delay(initTypewriter('Home'));
  });
};
