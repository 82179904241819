import KeenSlider from 'keen-slider'
import 'keen-slider/keen-slider.min.css'


export const initMarquee = () => {
  var animation = { duration: 24000, easing: (t) => t }
  var slider = new KeenSlider("#RS-marquee", {
    slides: {
      perView: 3,
      spacing: 64
    },
    loop: true,
    renderMode: "performance",
    drag: true,
    breakpoints: {
      '(max-width: 768px)': {
        slides: {
          perView: 4,
          spacing: 32
        }
      },
    }
  })

  // Custom arrow controls
  const prevArrow = document.querySelector('.RS-marquee-nav--prev');
  const nextArrow = document.querySelector('.RS-marquee-nav--next');

  prevArrow.addEventListener('click', () => {
    slider.prev();
    console.log('prev');
  });

  nextArrow.addEventListener('click', () => {
    slider.next();
    console.log('next');
  });

};